body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 991px) {
  .detail-list-vps {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .detail-list-vps {
    padding-top: 0px;
  }
}

@media only screen and (min-width: 992px) {
  .header-custom {
    max-width: 1340px;
  }
  .header-custom li a {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) {
  .auth-mobile {
    display: none;
  }
}

@media only screen and (max-width: 280px) {
  .auth-mobile {
    display: none;
  }
}


